<template>
  <div class="input input--checkbox" :class="{ 'input--error': error }">
    <input
      :id="id"
      :value="value"
      type="checkbox"
      :name="name"
      :checked="checked !== null ? checked : value"
      :required="required"
      @input="input"
    />
    <label :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputCheckbox',
  props: {
    value: { type: [Boolean, String], required: true },
    id: { type: String, required: true },
    name: { type: String, required: true },
    checked: { type: Boolean, default: null },
    required: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
  },
  methods: {
    input(ev) {
      this.$emit('input', ev.target.checked)
    },
  },
}
</script>

<style lang="scss">
.input--checkbox {
  input {
    @include visually-hidden;
  }

  label {
    display: block;
    padding-left: calc(var(--spacing) * 2);
    line-height: 1.6;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: rem(16px);
      height: rem(16px);
      border: rem(2px) solid var(--secondary-color);
      border-radius: 2px;
      transition: all 0.3s;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: rem(8px);
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23f6f3f0' d='M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z'/%3E%3C/svg%3E");
      width: rem(16px);
      height: rem(16px);
      transition: all 0.3s;
      color: var(--tertiary-color);
    }
  }

  input:checked + label {
    &::before {
      background: var(--secondary-color);
    }

    &::after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  &.input--error {
    label {
      &::before {
        border-color: var(--error-color);
      }
    }
  }
}
</style>
