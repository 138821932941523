<template>
  <div v-if="skeleton">
    <div class="input--skeleton skeleton"></div>
  </div>
  <div
    v-else
    class="input"
    :class="{
      'input--date': type === 'date',
      'input--tel': type === 'tel',
      'input--wait-for-submition': firstSubmit && !firstSubmited,
      'input--search': type === 'search',
      'input--icon': $slots.default,
      'input--disabled': disabled,
      'input--label-as-placeholder': labelPlaceholder,
      'input--focus': onFocus,
      'input--not-empty': value !== '',
    }"
  >
    <label
      :for="id"
      class="text-small pl-0-6 mb-0-2"
      :class="{ 'visually-hidden': !labelVisible }"
    >
      {{ label }}
    </label>
    <div class="input__container">
      <slot name="before" />
      <input
        :id="id"
        ref="input"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :class="{ error: showError, 'iti__tel-input': type === 'tel' && iti }"
        :aria-invalid="showError ? true : false"
        :aria-describedby="showError ? `${id}-error` : ''"
        :disabled="disabled || (edit && !onEdit)"
        :readonly="readonly"
        @input="debounce ? debounceInput($event) : input($event)"
        @focusin="onFocus = true"
        @focusout="onFocusOut"
      />
      <span class="input__line"></span>
      <slot />
      <transition name="fade" mode="out-in">
        <span v-if="showError" key="error" class="input__error-a11y"></span>
        <span
          v-else-if="isValid && value !== ''"
          key="valid"
          class="input__valid"
        >
          <icon name="check" />
        </span>
        <span
          v-else-if="!required && type !== 'search'"
          key="optional"
          class="input__optional"
          >{{ $t('input.optional') }}</span
        >
      </transition>
      <span v-if="help" ref="help" class="input__help tooltip">
        <icon name="info" />
      </span>
      <a
        v-if="edit && !onEdit"
        href="#"
        class="input__icon"
        @click.prevent="editInput()"
      >
        <span class="visually-hidden">{{ $t('action.edit') }}</span>
        <icon-edit />
      </a>
    </div>
    <transition name="appear-top">
      <div v-if="firstSubmited && inputErrors.length > 0" key="error-array">
        <span
          v-for="(e, index) in inputErrors"
          :key="index"
          class="input__error"
          >{{
            e.code === 'custom' && e.message
              ? e.message
              : $t(`input.error.${e.code}`)
          }}</span
        >
      </div>
      <div
        v-else-if="firstSubmited && !isValid"
        key="error"
        class="input__error"
      >
        {{ $t(error) }}
      </div>
    </transition>
    <slot name="help" />
  </div>
</template>

<script>
import * as EmailValidator from 'email-validator'

import IconEdit from '@/components/icons/Edit'
import Icon from '@/components/icons/Icon'

export default {
  name: 'FormInput',
  components: { Icon, IconEdit },
  props: {
    /**
     * Contenu du champs texte
     */
    value: { type: String, required: true },

    /**
     * Le type du champs
     */
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        ['text', 'email', 'tel', 'password', 'date', 'search'].includes(value),
    },

    /**
     * L'id du champs qui sert à lié le champs et son label
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Le label associé au champs
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Le label du champ est-il visible ?
     */
    labelVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * Le label du champ prend-il la place du placeholder quand il est vide ?
     */
    labelPlaceholder: {
      type: Boolean,
      default: false,
    },
    /**
     * Le placeholder du champs
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Est-ce que le champs est disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Est-ce que le champs est requis
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Est-ce que le champs est en readOnly
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Le champs doit-il attendre le premier envoie du formulaire avant de montrer les erreurs ?
     */
    firstSubmit: {
      type: Boolean,
      default: false,
    },
    /**
     * Ajoute un état d'édition pour pouvoir éditer le champs
     */
    edit: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom error
     */
    inputErrors: {
      type: Array,
      default: () => [],
    },
    /**
     * Permet de définir un temps avant de modifier la valeur du champs
     */
    debounce: {
      type: Boolean,
      default: false,
    },

    /**
     * Ajoute une validation personnalisé avant de définir que le champs est bon
     */
    customValidation: {
      type: Function,
      default: null,
    },

    /**
     * Affiche un bouton d'aide
     */
    help: {
      type: Boolean,
      default: false,
    },

    /**
     * Texte d'aide si le bouton d'aide est activé
     */
    helpText: {
      type: String,
      default: '',
    },

    /**
     * L'input est affiché en squelette de chargement
     */
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeout: null,
      firstSubmited: !this.firstSubmit,
      onEdit: false,
      onFocus: false,
      iti: null,
    }
  },
  computed: {
    isValid() {
      if (this.required) {
        if (!this.value || this.value === '') {
          /**
           * Inform that input is now invalid
           *
           * @event invalid
           */
          this.$emit('invalid')
          return false
        }
      }

      if (this.type === 'email') {
        if (!EmailValidator.validate(this.value)) {
          /**
           * Inform that input is now invalid
           *
           * @event invalid
           */
          this.$emit('invalid')
          return false
        }
      }

      if (this.type === 'phone') {
        if (!this.iti.isValidNumber()) {
          /**
           * Inform that input is now invalid
           *
           * @event invalid
           */
          this.$emit('invalid')
          return false
        }
      }

      if (this.customValidation) {
        if (!this.customValidation()) {
          /**
           * Inform that input is now invalid
           *
           * @event invalid
           */
          this.$emit('invalid')
          return false
        }
      }

      /**
       * Inform that input is now valid
       *
       * @event valid
       */
      this.$emit('valid')

      return true
    },
    showError() {
      if (this.firstSubmit) {
        if (!this.firstSubmited) {
          return false
        }
      }

      return !this.isValid || this.inputErrors.length > 0
    },
    error() {
      if (!this.isValid) {
        if (this.required) {
          if (!this.value || this.value === '') {
            return 'input.empty'
          }

          if (this.type === 'email') {
            if (!EmailValidator.validate(this.value)) {
              return 'input.email.wrong'
            }
          }
        }
      }

      return ''
    },
  },
  mounted() {
    this.$emit('input', this.$refs.input.value)

    if (this.type === 'tel') {
      this.initPhoneInput()
    }

    if (this.firstSubmit) {
      if (this.$refs.input.form) {
        this.$refs.input.form.addEventListener('submit', () => {
          this.firstSubmited = true
        })
      }
    }

    if (this.help && this.helpText !== '') {
      this.initTooltip()
    }
  },
  methods: {
    input(ev) {
      /**
       * Update input value
       *
       * @event input
       */
      this.$emit('input', ev.target.value)
    },

    debounceInput(ev) {
      this.$emit('keydown')
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.input(ev)
      }, 500)
    },

    editInput() {
      this.onEdit = !this.onEdit
      this.$nextTick(() => {
        this.focus()
      })
    },
    focus() {
      this.$refs.input.focus()
    },
    onFocusOut() {
      this.onFocus = false

      if (this.value !== '') {
        this.firstSubmited = true
      }

      if (this.type === 'tel') {
        this.$emit('input', this.iti.getNumber())
      }
    },

    async initPhoneInput() {
      const intlTelInput = await import(
        'intl-tel-input' /* webpackChunkName: 'intl-tel-input' */
      )
      await import('intl-tel-input/build/css/intlTelInput.css')

      const input = this.$refs.input
      this.iti = intlTelInput.default(input, {
        initialCountry: this.$i18n.locale.substring(0, 2),
        autoPlaceholder: 'polite',
        autoInsertDialCode: true,
        preferredCountries: ['fr', 'be', 'de', 'ch', 'gb', 'lu'],
        countrySearch: false,
        utilsScript: '/js/intl-tel-js-utils.js',
      })
    },

    async initTooltip() {
      const tippy = await import('tippy.js' /* webpackChunkName: 'tippy' */)
      await import(
        '@/scss/09-libs/_06-tippy.scss' /* webpackChunkName: 'tippy_css' */
      )
      await import(
        'tippy.js/animations/scale.css' /* webpackChunkName: 'tippy_animation_scale_css' */
      )

      tippy.default(this.$refs.help, {
        theme: 'dark',
        placement: 'top',
        animation: 'scale',
        content: this.helpText,
        arrow: true,
      })
    },
  },
}
</script>

<style lang="scss">
label {
  cursor: pointer;

  sup {
    color: var(--error-color);
  }
}

input[type='text'],
input[type='email'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='password'] {
  width: 100%;
  background: var(--tertiary-color);
  border: 0;
  border-radius: 0;
  height: var(--input-height);
  font-family: var(--primary-font-family);
  font-size: var(--base-font-size);
  line-height: 1;
  color: var(--text-body-color);
  transition: color 0.3s, border-color 0.3s;
  position: relative;
  padding: rem(1px) 0 rem(2px) rem($spacing * 3 / 5);
  font-weight: 300;
  letter-spacing: -0.03em;
  flex-grow: 1;

  &::placeholder {
    color: var(--secondary-color-2);
  }

  &:focus {
    outline: none;

    + .input__line {
      width: 100%;
    }
  }

  &:disabled {
    background: var(--secondary-color-5);
    border-color: #c0c0c0;
  }

  &:read-only {
    background: var(--secondary-color-5);
    color: var(--secondary-color-2);
    cursor: not-allowed;
  }
}

input[type='email'] {
  @include mq($from: tablet) {
    min-width: rem(240px);
  }
}

.input {
  margin-bottom: var(--spacing);

  &:not(.input--wait-for-submition) {
    input[type='text'],
    input[type='date'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='email'] {
      &.error {
        color: var(--error-color);

        + .input__line {
          background: var(--error-color);
          width: 100%;
        }

        &::placeholder {
          color: rgba($error-color, 0.5);
        }
      }
    }
  }

  &.input--wait-for-submition {
    .input__error {
      @include visually-hidden;
    }
  }

  + .input-error {
    margin-top: rem(-$spacing * 0.5);
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    border: solid var(--secondary-color-3);
    border-width: 0 0 rem(1px);
    background: var(--tertiary-color);

    .iti {
      width: 100%;
    }
  }

  &__line {
    position: absolute;
    bottom: rem(-1px);
    left: 50%;
    width: 0;
    height: rem(1px);
    background: var(--secondary-color);
    transform: translateX(-50%);
    transition: width 0.3s;
    z-index: 2;
  }

  &__error {
    font-size: var(--small-font-size);
    font-weight: 300;
    letter-spacing: -0.03em;
    color: var(--error-color);
    margin: rem($spacing * 2 / 5) 0 rem($spacing * 3 / 5) rem($spacing * 3 / 5);
  }

  &__error-a11y,
  &__valid,
  &__help {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--input-height);
    height: var(--input-height);
    background-color: var(--tertiary-color);
  }

  &__error-a11y {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem(8px);
      height: rem(8px);
      border-radius: 50%;
      background: var(--error-color);
    }
  }

  &__help {
    &::after {
      content: none !important; // To avoid css cascad on tooltip
    }
  }

  &__valid {
    color: var(--secondary-color);
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(-1px);
    padding: rem($spacing);
    transition: all 0.3s;

    svg,
    img {
      width: rem(20px);
      height: rem(20px);
      transition: all 0.3s;
    }

    @include on-hover-and-focus {
      padding: rem($spacing - 2px);

      svg,
      img {
        width: rem(24px);
        height: rem(24px);
      }
    }
  }

  &__optional {
    font-size: rem(11px);
    color: var(--secondary-color-3);
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  &--disabled {
    .input__valid {
      background: var(--secondary-color-5);
    }
  }

  &--search {
    .input__container {
      border-bottom: 0;
    }

    input {
      background: white;
      box-shadow: 5px 5px 0 0 RGB(16 24 32 / 0.1);
    }

    .input__line {
      display: none;
    }
  }

  &--icon {
    input[type='text'],
    input[type='date'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='email'] {
      padding-right: rem($spacing * 5);

      &:focus {
        padding-right: rem($spacing * 3 - 1px);
      }
    }

    .input__valid,
    .input__error-a11y {
      right: rem(45px);
    }
  }

  &--skeleton {
    height: rem(50px);
  }

  &--label-as-placeholder {
    position: relative;

    input {
      padding-left: 0;
    }

    label {
      position: absolute;
      top: calc(var(--input-height) / 2);
      left: 0;
      padding: 0;
      transform: translateY(-50%);
      z-index: 1;
      font-size: var(--base-font-size);
      transition: all 0.3s var(--asphalte-animation-function);
    }

    &.input--date,
    &.input--tel,
    &.input--not-empty,
    &.input--focus {
      label {
        top: 0;
        transform: translateY(-50%);
        font-size: var(--extra-small-font-size);
        color: var(--secondary-color-2);
      }
    }

    &.input--not-empty {
      label {
        transition: none;
      }
    }
  }
}
</style>
