<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    enable-background="new 0 0 500 500"
    xml:space="preserve"
    class="icon"
  >
    <path
      d="M0,395.8V500h104.2l307.2-307.2L307.2,88.7L0,395.8z M491.9,112.3c10.8-10.8,10.8-28.3,0-39.2l-65-65
	c-10.8-10.8-28.3-10.8-39.2,0L336.9,59L441,163.1L491.9,112.3z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconEdit',
}
</script>
